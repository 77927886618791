<template>
  <main data-aos="fade-up" data-aos-duration="1000">

    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-12">
            <div class="page-title">
              <h1>Services</h1>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-area pt-120 pb-90">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-5">
            <div class="about-left-side pos-rel mb-30">
              <div class="medical-icon-brand-2 medical-icon-brand-3">
                <img src="../assets/img/about/medical-brand-icon-border.png" alt="">
              </div>
              <div class="about-front-img pos-rel">
                <img src="../assets/img/services-who-we-are.png" alt="">
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-7">
            <div class="about-right-side pt-4 mb-30">
              <div class="about-title mb-20">
                <h2>Health Travel Secure</h2>
              </div>
              <div class="our-destination">
                <div v-if="loading" class="loading">
                  <img src="../assets/loading-animacion.gif" alt="">
                </div>
                <ul v-else>
                  <li v-for="(item, i) in services_data" :key="i">
                    <div class="list-title">
                      <div class="list-img">
                        <img :src="item.icon" alt="">
                      </div>
                      <h4>{{item.title}}</h4>
                    </div>
                    <ol v-if="item.features" class="features-list">
                      <li v-for="(itemFeatures, i) in item.features" :key="i">
                        <div class="features-list-text" v-html="itemFeatures.text"></div>
                      </li>
                    </ol>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
  import api from "../services/api";
    export default {
        name: "services",
        data() {
            return {
                serviceFeatures_data: [],
                services_data: [],
                loading: true
            }
        },
        async mounted() {
            window.scrollTo(0, 0);
            this.loading = true;
            try {
                this.services_data = await api.services(6);
                if(!this.services_data) this.services_data = [];
                for (const value of this.services_data) {
                    if (value.id !== null) {
                        let serviceFeatures = await api.serviceFeatures(value.id);
                        if (serviceFeatures !== null) {
                            value.features = serviceFeatures;
                        }
                    }
                }
                this.loading = false;
                for (const value of this.services_data) {
                    if (value.icon !== null && typeof value.icon === "number") {
                        let img = await api.getFiles(value.icon);
                        if (img !== null) {
                            value.icon = img.data['data']['full_url'];
                        }
                    }
                }
            } catch {
                this.serviceFeatures_data = [];
                this.loading = false;
            }
        }
    }
</script>

<style scoped>
  .loading {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .breadcrumb-bg {
    background-image: url("../assets/img/services-banner.png");
    background-position: top;
    position: relative;
  }
  .breadcrumb-bg::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.72);
  }
  .breadcrumb-bg .container {
    position: relative;
    z-index: 2;
  }
  .service-box {
    padding: 53px 20px;
  }
  .service-box ul {
    text-align: left;
  }
  .service-box ul li {
    margin-bottom: 10px;
    padding-left: 25px;
    position: relative;
  }
  .service-box ul li:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #e1245359;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  .list-title {
    padding: 7px 0;
    border-radius: 51px;
    display: flex;
    align-items: center;
  }
  .list-title h4 {
    margin-left: 15px;
    margin-bottom: 0;
    color: #223645;
  }
  .list-title .list-img {
    background: #fff9fb;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .list-title img {
    width: 70px;
  }
  .features-list {
    padding-left: 88px;
  }
  .features-list li {
    position: relative;
  }
  .features-list li::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #e43a64b0;
    border-radius: 50%;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
  }
  .features-list-text p {
    margin-bottom: 0;
  }
</style>
